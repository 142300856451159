<template>
    <client-page>
        <sub-visual-business-domestic />

        <section class="section">
            <v-container>

                <page-title-operate />

                <tab-business-domestic />

                <business-item imageGrid="4" tit="동탄어울림종합사회복지관" file="dt" url="http://www.dongtanwith.or.kr">
                    <ul class="dot-texts page-text">
                        <li class="dot-text">
                            시설유형 : 종합사회복지관
                        </li>
                        <li class="dot-text">
                            소재지 : 경기도 화성시 동탄대로시범길 133
                        </li>
                        <li class="dot-text">
                            설립일 : 2018년 4월 11일
                        </li>
                        <li class="dot-text">
                            종사자수 : 22명
                        </li>
                        <li class="dot-text">
                            시설장 : 최성규
                        </li>
                        <li class="dot-text">
                            주요사업 : 복지사각지대 발굴 ‘숨은이웃찾기’,사례관리 ONE-NETWORK 사업, 1인가구 안부확인 및, 정서지원사업, 찾아가는 문화체험 DAY, 메타버스 플랫폼을 활용한 지역주민소통, 돌봄취약 저소득 아동 학습,정서,건강 채움 프로그램, 아동방과후교실 ‘아이들세상’, 저소득 아동, 오케스트라 지원 사업, 자원봉사동아리 사업IT교육지원사업, ESG 경영실천 지역주민 상생 챌린지
                        </li>
                    </ul>
                </business-item>

                <business-item imageGrid="4" tit="강남지역자활센터" file="gnc" url="https://www.gangnam.go.kr/office/gjhope">
                    <ul class="dot-texts page-text">
                        <li class="dot-text">
                            시설유형 : 지역자활센터
                        </li>
                        <li class="dot-text">
                            소재지 : 서울시 강남구 개포로38길 12
                        </li>
                        <li class="dot-text">
                            설립일 : 2001년 7월 1일
                        </li>
                        <li class="dot-text">
                            종사자수 : 15명(센터장 포함)
                        </li>
                        <li class="dot-text">
                            시설장 : 이경화
                        </li>
                        <li class="dot-text">
                            운영방식 : 보건복지부 지정 운영
                        </li>
                        <li class="dot-text">
                            사업단수 : 18개 사업단, 4개 자활기업
                        </li>
                        <li class="dot-text">
                            주요사업 : 자활근로사업단 운영 및 참여자 관리, 저소득층 자활을 위한 사례관리 및 취업지원 등
                        </li>
                    </ul>
                </business-item>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisualBusinessDomestic from "@/components/client/sub/sub-visual-business-domestic.vue";
import TabBusinessDomestic from "@/components/client/sub/tab-business-domestic.vue";
import PageTitleOperate from '@/components/client/sub/page-title-operate.vue';
import BusinessItem from "@/components/client/sub/business-item.vue";
export default {
    components: {
        ClientPage,
        SubVisualBusinessDomestic,
        TabBusinessDomestic,
        PageTitleOperate,
        BusinessItem,
    },
};
</script>
